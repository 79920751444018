<template>
    <v-table ref="driverTable"
             :table-head="tableHead"
             :table-data="currentDriverData"
             :is-show-page="false"
             :default-sort="defaultSort"
             @deleteRow="deleteDriver"
             @editRow="editDriver"
             @rowClick="handleRowClick"
             @sortChange="handleSortChange"
             class="driver-content">
        <template slot="Name" slot-scope="scope">
            <div class="avatar-area">
                <div>
                    <el-avatar :src="getPhoto(scope.row.OtherPictures)" :size="48" fit="fill">
                        <img src="../../../assets/image/default-photo.svg"/>
                    </el-avatar>
                </div>
                <div class="text-overflow-ellipsis"
                     :title="scope.row.Name">{{scope.row.Name}}
                </div>
                <!--</el-link>-->
            </div>
        </template>
        <template slot="FaceID" slot-scope="scope">
            <span v-if="scope.row.FRPictures"> <i class="el-icon-check state-check"></i></span>
            <span v-else> <i class="el-icon-close state-no-check"></i></span>
        </template>

    </v-table>
</template>

<script>
    import vTable from '../../../components/common/Table';
    import sort from "@/assets/js/Sort";
    import bus from "../../../utils/bus"

    export default {
        name: "Driver",
        components: {
            vTable
        },
        data() {
            return {
                currentPage: 1,
                tableHead: [],
                pageWidth: 0,
                context: null,
                currentDriverData: null,
                driverData: null,
            }
        },
        computed: {
            driverList: function () {
                if (this.$store.getters.driverList)
                    this.scrollPage();
                return this.$store.getters.driverList;
            },
            defaultSort: function () {
                if (this.context && this.context.sort)
                    return this.context.sort;
                return {prop: 'Name', order: 'ascending'}
            },
            driverSearchKey() {
                return this.$store.getters.getDriverSearchKey;
            }
        },
        watch: {
            '$store.getters.screen'() {
                this.tableHead = this.getTableHead();
            },
            driverList(val) {
                if (val) {
                    this.driverData = [...val];
                    this.currentDriverData = this.sortData(this.driverData, this.defaultSort);
                }
            },
            driverSearchKey(val) {
                var driverData;
                if (val)
                    driverData = this.driverData.filter((item) => {
                        function checkFind(prop) {
                            if(prop && prop.toLowerCase().indexOf(val.toLowerCase()) >=0)
                                return true;
                            return false;
                        }
                        if (checkFind(item.Name))
                            return true;
                        else if (checkFind(item.CellPhone))
                            return true;
                        else if (checkFind(item.LoginName))
                            return true;
                        else if (checkFind(item.License))
                            return true
                        return false;
                    })
                else {
                    driverData = this.driverData;
                }

                this.currentDriverData = this.sortData(driverData, this.$refs.driverTable.getCurrentSort());
            }
        },
        methods: {
            getTableHead() {
                var pageWidth = this.getPageWidth();
                console.log("pageWidth = " + pageWidth);
                return [
                    {
                        name: this.$t('driver.driverName'),
                        prop: "Name",
                        sortable: 'custom',
                        minWidth: "150"
                    },
                    {
                        name: this.$t('driver.loginID'),
                        prop: "LoginName",
                        minWidth: "160"
                    },
                    {
                        name: this.$t('driver.contactNumber'),
                        prop: "CellPhone",
                        minWidth: "150"

                    },
                    {
                        name: this.$t('driver.licenseNumber'),
                        prop: "License",
                        minWidth: "150",

                    },
                    {
                        name: this.$t('driver.permittedVehicles'),
                        prop: "VehicleCount",
                        align: "center",
                        minWidth: pageWidth > 1366 ? "155" : "88"

                    },
                    {
                        name: this.$t('driver.emergContact'),
                        prop: "Contact",
                        minWidth: "140"
                    }, {
                        name: this.$t('driver.faceIDPhoto'),
                        prop: "FaceID",
                        align: "center",
                        minWidth: pageWidth > 1366 ? "120" : "76"

                    }

                ]
            },
            selectable(row, rowIndex) {
                if (rowIndex == driverData.length - 1)
                    return false;
                return true;
            },
            handleRowClick(row) {
                this.rememberContext();
                this.$router.push({
                    name: "viewDriver",
                    query: {id: row.Id, rememberCtx: true}
                }).catch();
            },
            handleSortChange(data) {
                this.currentDriverData = this.sortData(this.currentDriverData, data);
                this.rememberContext();
            },
            sortData(data, sortData) {
                function SortFunc(value1, value2) {
                    var compare = function (a, b, prop) {
                        return prop == "Name" ? sort.NameSort(a[prop], b[prop]) : a[prop] - b[prop];
                    }
                    if (sortData.order == 'ascending')
                        return compare(value1, value2, sortData.prop);
                    else {
                        return compare(value2, value1, sortData.prop);
                    }
                }

                return data.sort(SortFunc);
            },

            deleteDriver(delIds, callback) {
                this.$serverApi.driver.deleteDriver(delIds, () => {
                    this.$store.dispatch("getDriverList").then(() => {
                        if (callback) {
                            callback();
                        }
                    });
                    this.$message(
                        {
                            message: this.$t('globalText.deletedSuccessfully'),
                            type: 'success'
                        }
                    )

                }, (error) => {
                    if (callback) {
                        callback();
                    }
                });
            },
            // resetPassword() {
            //     var Ids = this.$refs.driverTable.getSelectedIds();
            //     this.$customConfirm("Are you sure to reset password?", {
            //         type: 'warning'
            //     }).then(() => {
            //         console.log(Ids.toString());
            //         Ids.forEach((id) => {
            //             this.$serverApi.driver.updateDriver(
            //                 {
            //                     Id: id,
            //                     Password: "000000"
            //                 }
            //             )
            //         })
            //
            //     })
            // },
            editDriver(data) {
                this.rememberContext();
                this.$router.push({
                    name: "editDriver",
                    query: {id: data.Id, rememberCtx: true}
                }).catch();
            },
            getPhoto(paths) {
                if (paths && paths.length > 0) {
                    return this.$baseURL + paths[0];
                }

                return null;
            },
            getPageWidth() {
                return document.body.clientWidth;

            },
            scrollPage() {
                var scrollTop = this.context ? this.context.scrollTop : null;
                if (scrollTop) {
                    this.$nextTick(() => {
                        this.$parent.scrollTo(scrollTop);
                    })
                }
            },
            rememberContext() {
                var scrollTop = this.$parent.getScrollTop();
                var sort = this.$refs.driverTable.getCurrentSort();
                var context = {
                    scrollTop: scrollTop,
                    sort: sort
                };
                this.$store.commit("setDriverListCtx", context);
            },
            getContext() {
                var ctx = this.$store.state.driverData.driverListCtx;
                if (ctx) {
                    this.context = ctx;
                }
            }
        },
        created() {
            this.$store.dispatch("getDriverList");
            this.tableHead = this.getTableHead();
            this.getContext();

        },
        beforeDestroy(){
            //清除搜索记录
            this.$store.commit('setDriverSearchKey', '');
            bus.$emit("clearDriverSearchKey");
        }


    }
</script>

<style scoped lang="stylus">
    @import "~@/assets/stylus/color.styl"
    .driver-content {
        padding: 20px 20px 20px 30px;
        /*height: 100vh;*/
    }

    >>> .el-table td {
        padding 3px 0;
    }

    >>> .el-table th {
        padding 6px 0;
    }

    .driver-content .driver-name {
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        position: absolute;
        transform: translateY(50%);
        max-width: calc(100% - 55px)
    }

    .driver-content .face-id-icon {
        font-size 18px;
    }

    .avatar-area div {
        float: left;
        vertical-align: middle;
        margin-right: 5px;
    }

    .avatar-area > div:first-child {
        width: 48px;
        height: 48px;
    }

    .avatar-area > div:last-child {
        width: calc(100% - 58px);
        height: 48px;
        line-height: 48px;
    }

    >>> .el-avatar img {
        width: 100%;
    }

</style>
